/* src/components/routes/Landing/Terms/Terms.css */


.terms-container {
    position: relative;
    display: flex;
    flex-direction: column;
    color: black;
    overflow: hidden;
    margin-top: 1rem;
}


.terms-container::before {
    content: "";
    position: absolute;
    top: -150px;
    left: 60px;
    width: 800px;
    height: 1200px;
    background: linear-gradient(70deg, #651FFF 40%, #00BCD4 92.81%);
    opacity: 0.25; 
    transform: rotate(-50deg);
    z-index: -10; 
}

.terms-container::after {
    content: "";
    position: absolute;
    top: 800px;
    left: 450px;
    width: 800px;
    height: 750px;
    background: linear-gradient(120deg, #651FFF 15%, #00BCD4 95%);
    opacity: 0.25;
    transform: rotate(-45deg);
    z-index: -10; 
}


.terms-heading{
    font-size: 2.5em;
    font-weight: 800;
}

.terms-section{
    text-align: left;
    margin: auto;
    width: 90vw;
    margin-top: 3rem;
}

.terms-subheadings{
    font-size: 1.75em;
    font-weight: 800;
}

.terms-sheadings{
    font-size: 1.4em;
    text-decoration:underline;

}

/* for mobile screens  */
@media only screen and (min-width: 320px) and (max-width: 768px) {

    .terms-container::before {
        top: 50px;
        left: 60px;
        width: 1000px;
        height: 1500px;
    }
    .terms-container::after {
        top: 1300px;
        left: 10px;
        width: 900px;
        height: 1000px;
    }
}