/* src/components/routes/LandingPage/Footer/Footer.css */



.footer-container{
    position: relative;
    display: flex;
    flex-direction: row;
    color: black;
    overflow: hidden;
    background-color: white;
}
.contact .headi{
    margin-top: -10rem;
}
/* 
.footer-container::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -300px;
    width: 800px;
    height: 1000px;
    background: linear-gradient(70deg, #651FFF 40%, #00BCD4 92.81%);
    opacity: 0.25; 
    transform: rotate(50deg);
    z-index: -10; 
}

.footer-container::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 500px;
    width: 800px;
    height: 750px;
    background: linear-gradient(120deg, #651FFF 15%, #00BCD4 95%);
    opacity: 0.25;
    transform: rotate(-20deg);
    z-index: -10; 
} */



.footer-content-first,
.footer-content-second{
    width: 30%;
    margin: auto;
    margin-top: 5rem;
}

.footer-content-third{
    width: 40%;
    margin: auto;
}

.footer-content-first{
    display: flex;
    flex-direction: column;
}

.footer-logo{
    width: 15rem;
    margin-top: -1rem;
} 

.social-links{
    margin-bottom: 1rem;
}

.social-icon{
    margin: 1rem;
    width: 1.5rem;
}

.ft-sec{
    margin-top: 8rem;

}

.cont-links{
    text-decoration: none;
    color: #292a5c;
}

.deets{
    font-size: larger;
    font-weight: 600;
    text-align: left;
    margin-bottom: 1rem;
    margin-left: 3rem;
}




.footer-links{
    display: flex;
    text-decoration: none;
    color: blue;
    font-size: larger;
    justify-content: left;
    font-weight: 600;
    margin-top: 9rem;
}

.map-container{
    margin-top:6rem;
}

/* ******* */

/* for mobile screens  */
@media only screen and (min-width: 320px) and (max-width: 900px) {


    .footer-container{
        display: flex;
        flex-direction: column;
    }

    .contact .headi{
        margin-top: -23rem;
        font-size: 2.5em;
    }
    
    .footer-content-first,
    .footer-content-second,
    .footer-content-third{
        width: 100%;
    }
    
    
    .footer-logo{
        width: 14rem;
        margin: auto;
    }

    .footer-links{
        text-align: left;
        margin-left: 5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .ft-sec{
        margin-top: 1rem;
    
    }
    

    .deets{
        text-align: left;
        margin-left: 6rem;
    }

    .map-container{
        margin-top:2rem;
        margin-bottom: 2rem;
    }
    
}