/* src/components/routes/Landing/Team/Team.css */

.team-container {
    position: relative;
    overflow: hidden;
    background-color: black;
}

.team-members {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 0.1rem;
}

.team-member {
    position: relative;
    width: 15%;
    overflow: hidden;
    margin: auto;
}

.team-member .info-icon {
    display: none;
}


.team-member img {
    width: 100%;
    height: 10rem;
    display: block;
    border-radius: 5px;
    transition: transform 0.3s ease, filter 0.3s ease;
    background: linear-gradient(to top, black 30%, rgba(146, 218, 247, 0.527));
}

.team-member .overlayy {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.team-member:hover img {
    transform: scale(1.5);
    filter: brightness(50%);
}

.team-member:hover .overlayy {
    opacity: 1;
}

.textin {
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
}

.textinn{
    font-size: 1rem;
    font-weight: 300;
}


/* *********** */


/* for mobile screens  */
@media only screen and (min-width: 320px) and (max-width: 850px) {

    .team-container {
        position: relative;
        overflow: hidden;
    }

    .team-members {
        gap: 0.5rem;
    }

    .team-member {
        width: 60%;
    }

    .team-member img {
        margin: auto;
        width: 100%;
        height: auto;
    }
    .team-member .info-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        color: white;
        font-size: 1.5rem;
        z-index: 1;
        opacity: 0.7;
    }
    
}
