/* src/components/routes/Landing/Header/Header.css */


.headerr{
    position: absolute;
    top:1rem;
    z-index: 1;
    margin-left: 1rem;
}


.main-logo {
    width: 10rem;
    height: 5rem;
}


/* for mobile screens  */
@media only screen and (min-width: 320px) and (max-width: 900px) {
    
    .headerr{
        margin-left: 0.5rem;
    }
    
    .main-logo {
        width: 10rem;
        height: 5rem;
    }
}